import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";

interface IListPagesPortfolio {
    sortPagePortfolio: any,
    setSelectPagePortfolio(value: any): any,
    setSelectShowPagePortfolio(value: any): any
    setSelectEditPagePortfolio(value: any): any
}

const ListPagesPortfolio: FC <IListPagesPortfolio> = ({sortPagePortfolio, setSelectPagePortfolio, setSelectShowPagePortfolio, setSelectEditPagePortfolio}) => {
    const [date, setdate] = useState<any>([]);

    const columns = [
        {
            id: "index",
            name: <span className='font-weight-bold text-center fs-13'>№</span>,
            selector: (row: any, index: any) => index+1,
            sortable: false,
            center: true,
            width: '60px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Название страницы</span>,
            selector: (row: any) => row.type_page_portfolio?.name,
            sortable: false,
        },
        {
            name: <span className='font-weight-bold text-center fs-13'>Дата создания</span>,
            selector: (row: any) => moment(row.created_at).format('DD.MM.YYYY'),
            sortable: false,
            width: '150px'
        },
        {
            id: "edit",
            name: <span className='font-weight-bold text-center fs-13'>Изменить</span>,
            //selector: (row: any) => <i className="bx bx-lock fs-3"></i>,
            cell: (row: any, index: any) => <i
                id={'editPagePort' + index.toString()}
                className="bx bx-edit fs-3"
                onClick={() => {
                    setSelectPagePortfolio(row)
                    setSelectShowPagePortfolio(false)
                    setSelectEditPagePortfolio(true)
                }}
            ></i> ,
            sortable: false,
            center: true,
            width: '96px'
        },
        {
            id: "block",
            name: <span className='font-weight-bold text-center fs-13'>Доступ</span>,
            cell: (row: any) => row.public ? '' : <i className="bx bx-lock fs-3"></i>,
            sortable: false,
            center: true,
            width: '81px'
        }
    ];
    return (
        <div>
            <Card className="team-box">
                <CardBody className="p-4">
                    <DataTable
                        columns={columns}
                        data={sortPagePortfolio}
                        noDataComponent={'Нет страниц'}
                        defaultSortFieldId={"count"}
                        defaultSortAsc={false}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={(row) => {
                            setSelectPagePortfolio(row)
                            setSelectShowPagePortfolio(true)
                            setSelectEditPagePortfolio(false)
                        }}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default ListPagesPortfolio;
