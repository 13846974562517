import React, {FC, useState, useRef, useEffect} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";

interface ITitlePortfolioPage{
    dispatch(value: any): any,
    portfolio: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean) : any
}

const TitlePortfolioPage: FC <ITitlePortfolioPage> = ({dispatch, portfolio, setPortfolio, setSelectEditPagePortfolio}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [images, setImages] = useState<{img1: any, img2: any}>({ img1: '', img2: '' });

    const handleCombineImages = () => {
        AxiosClient.get('get/pattern/portfolio', { params: { nameImage: 'title.png' } })
            .then(r => {
                setImages({img1: r.data.img1, img2: portfolio?.photo_download});
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        if (images.img1 && images.img2) {
            const canvas: HTMLCanvasElement | null = canvasRef.current;
            if (!canvas) return;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;

            const img1 = new Image();
            const img2 = new Image();

            img1.src = `data:image/png;base64,${images.img1}`;
            img2.src = images.img2;

            Promise.all([
                new Promise<void>((resolve) => { img1.onload = () => resolve(); }),
                new Promise<void>((resolve) => { img2.onload = () => resolve(); }),
            ]).then(() => {
                ctx.drawImage(img2, 75, 1465, 1055, 1345);
                ctx.drawImage(img1, 0, 0, 2480, 3508);
                ctx.font = "204px 'domino'";
                ctx.fillStyle = "DarkBlue";
                ctx.textAlign = "left";
                ctx.fillText(portfolio?.last_name, 1300, 1200);
                ctx.fillText(portfolio?.name, 1300, 1400);
                ctx.fillText(portfolio?.middle_name, 1300, 1600);
                handleDownload();
            }).catch(error => {
                console.error("Ошибка при загрузке изображений:", error);
            });
        }
        else if (images.img1 && !(images.img2)){
            const formData = new FormData();
            formData.append('photo_page', '');
            formData.append('id', portfolio?.id);
            formData.append('uid', portfolio?.child_uid);
            formData.append('last_name', portfolio?.last_name);
            formData.append('name', portfolio?.name);
            formData.append('middle_name', portfolio?.middle_name);
            formData.append('photo', '');
            formData.append('path_photo', portfolio?.path_photo);
            formData.append('path_photo_page', portfolio?.path_photo_page);

            AxiosClient.post('photo/save/portfolio', formData, {headers: {"Content-Type": 'multipart/form-data'}})
                .then(r => {
                    setPortfolio(r.data);
                    setSelectEditPagePortfolio(false)
                    dispatch(setIsSend(false))
                })
                .catch(error => {
                    console.error("Ошибка при выгрузке титульной страницы:", error);
                });
        }
    }, [images]);

    const handleDownload = () => {
        const canvas: any = canvasRef.current;
        canvas.toBlob((blob: any) => {
            const formData = new FormData();
            formData.append('photo_page', blob, 'title.png');
            formData.append('id', portfolio?.id);
            formData.append('uid', portfolio?.child_uid);
            formData.append('last_name', portfolio?.last_name);
            formData.append('name', portfolio?.name);
            formData.append('middle_name', portfolio?.middle_name);
            formData.append('photo', portfolio?.photo);
            formData.append('path_photo', portfolio?.path_photo);
            formData.append('path_photo_page', portfolio?.path_photo_page);

            AxiosClient.post('photo/save/portfolio', formData, {headers: {"Content-Type": 'multipart/form-data'}})
                .then(r => {
                    setPortfolio(r.data);
                    setSelectEditPagePortfolio(false)
                    dispatch(setIsSend(false))
                })
                .catch(error => {
                    console.error("Ошибка при выгрузке титульной страницы:", error);
                });
        }, 'image/png');
    };

    return (
        <div className="display-none">
            <canvas ref={canvasRef} width={2480} height={3508} className="display-none"/>
            <br />
            <button onClick={handleCombineImages} id='handleCombineImagesTitle'>Combine Images</button>
        </div>
    );
};

export default TitlePortfolioPage;
