import React from 'react';
import {Helmet} from "react-helmet";
import GameComponent from "../components/Game/GameComponent";

const Game = () => {
    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Игры</title>
            </Helmet>
            <GameComponent/>
        </div>
    );
};

export default Game;
