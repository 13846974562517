import {Application, Container, Graphics, ICanvas, Sprite, Text} from "pixi.js";
import Shot from "../Constructor/Shot";
import Size from "../utils/Size";
import {Viewport} from "pixi-viewport";
import Button from "../UI/Button";
import AxiosClient from "../../api/AxiosClient";
import {IGame} from "../models/IGame";
import {Sound, sound} from "@pixi/sound";
import Btn from '../../assetsGame/b_3.png'
import bgModal from '../../assetsGame/f.png'
import bgStartEnd from '../../assetsGame/icons/bgStartEnd.jpg'

class Game {
    _app: Application<ICanvas>;
    shots: Shot[] = [];
    constructor(app: Application<ICanvas>, idGame: number) {
        this._app = app
        const size = new Size(app)
        const viewport = new Viewport({
            screenWidth: app.view.width,
            screenHeight: app.view.height,
            worldWidth: app.view.width,
            worldHeight: app.view.height,

            events: app.renderer.events
        })
        const onePercentX = app.view.width / 100
        const onePercentY = app.view.height / 100
        const shots = new Container();
        const start = Sprite.from(bgStartEnd)
        start.anchor.set(0.5)
        start.width = app.view.width
        start.height = app.view.height
        start.x = app.view.width / 2
        start.y = app.view.height / 2
        const btn = Sprite.from(Btn)
        btn.cursor = 'pointer'
        btn.interactiveChildren = false
        btn.anchor.set(0.5)
        btn.scale.set(0.7)
        const text = new Text('Начать играть', {fontSize: 30})
        text.anchor.set(0.5)
        text.scale.set(3.5)
        text.x = btn.width / 2
        text.y = btn.height / 2
        btn.addChild(text as any)
        start.addChild(btn as any)
        btn.onpointerdown = () => {
            start.destroy()
            viewport.addChild(shots as any);
            AxiosClient.get<IGame>(`/game/game/${idGame}`)
                .then((r) => {
                    const arrShots: Container[] = []
                    const arrBgSound: Sound[] = []
                    const arrQuestion: Sound[] = []
                    let shotIndex = 0;
                    r.data.shots.forEach((sh) => {
                        const shot = new Container()
                        const bgImage = Sprite.from(sh.background.base64)
                        bgImage.width = app.view.width
                        bgImage.height = app.view.height
                        const maskBgImage = new Graphics();
                        maskBgImage.beginFill(0xFFFFFF);
                        maskBgImage.drawRoundedRect(0, 0,  app.view.width, app.view.height, 10)
                        maskBgImage.endFill();
                        bgImage.mask = maskBgImage;
                        shot.addChild(bgImage as any)

                        sh.personages.forEach((el) => {
                            const personage = Sprite.from(el.base64);
                            personage.x = el.pivot.percentX ? onePercentX * el.pivot.percentX : el.pivot.x
                            personage.y = el.pivot.percentY ? onePercentY * el.pivot.percentY : el.pivot.y
                            personage.scale.set(el.pivot.size)
                            personage.anchor.set(0.5);
                            personage.onpointerdown = () => {
                                const mySound = sound.find(String(sh.id));
                                if (mySound) {
                                    mySound.play({
                                        volume: 1
                                    });
                                }
                            }
                            shot.addChild(personage as any)
                        })

                        sh.textures.forEach((el) => {
                            const texture = Sprite.from(el.base64);
                            texture.x = el.pivot.percentX ? onePercentX * el.pivot.percentX : el.pivot.x
                            texture.y = el.pivot.percentY ? onePercentY * el.pivot.percentY : el.pivot.y
                            texture.scale.set(el.pivot.size)
                            texture.anchor.set(0.5);
                            texture.onmouseover = () => {
                                texture.scale.set(el.pivot.size + 0.1)
                            }
                            texture.onmouseout = () => {
                                texture.scale.set(el.pivot.size)
                            }
                            texture.onpointerdown = () => {
                                if (el.pivot.type === 0){
                                    const id = el.id + 'true'
                                    if(!sound.exists(String(id))) {
                                        sound.add(String(id), el.base64TextTrue);
                                    }
                                    const mySound = sound.find(String(id));
                                    if (mySound) {
                                        const ques = sound.find(String(sh.id));
                                        if (ques.isPlaying){
                                            ques.stop()
                                        }
                                        mySound.play({
                                            volume: 1,
                                            complete: () => {
                                                shotIndex++
                                                if (shotIndex < arrShots.length){
                                                    this.showShot(arrShots[shotIndex], shots, arrBgSound[shotIndex], arrQuestion[shotIndex]);
                                                } else {
                                                    const mySound = sound.find(String(sh.background_sound.id));
                                                    mySound.stop()
                                                    shots.destroy()
                                                    const end = Sprite.from(bgStartEnd)
                                                    end.anchor.set(0.5)
                                                    end.width = app.view.width
                                                    end.height = app.view.height
                                                    end.x = app.view.width / 2
                                                    end.y = app.view.height / 2
                                                    const games = Sprite.from(Btn)
                                                    games.y -= games.height / 2
                                                    games.cursor = 'pointer'
                                                    games.interactiveChildren = false
                                                    games.anchor.set(0.5)
                                                    games.scale.set(0.7)
                                                    games.onpointerdown = () => {
                                                        window.location.href = '/games'
                                                    }
                                                    const text = new Text('К играм', {fontSize: 30})
                                                    text.anchor.set(0.5)
                                                    text.scale.set(3.5)

                                                    const restart = Sprite.from(Btn)
                                                    restart.y += restart.height / 2
                                                    restart.cursor = 'pointer'
                                                    restart.interactiveChildren = false
                                                    restart.anchor.set(0.5)
                                                    restart.scale.set(0.7)
                                                    restart.onpointerdown = () => {
                                                        window.location.reload()
                                                    }
                                                    const textRestart = new Text('Начать заного', {fontSize: 30})
                                                    textRestart.anchor.set(0.5)
                                                    textRestart.scale.set(3.5)

                                                    restart.addChild(textRestart as any)
                                                    end.addChild(restart as any)
                                                    games.addChild(text as any)
                                                    end.addChild(games as any)
                                                    viewport.addChild(end as any)
                                                }
                                            }
                                        });
                                    }
                                }
                                if (el.pivot.type === 1){
                                    const id = el.id + 'false'
                                    if(!sound.exists(String(id))) {
                                        sound.add(String(id), el.base64TextFalse);
                                    }
                                    const mySound = sound.find(String(id));
                                    if (mySound) {
                                        const ques = sound.find(String(sh.id));
                                        if (ques.isPlaying){
                                            ques.stop()
                                        }
                                        mySound.play({
                                            volume: 1
                                        });
                                    }
                                }
                                if (el.pivot.type === 2){
                                    const id = el.id + 'noActive'
                                    if(!sound.exists(String(id))) {
                                        sound.add(String(id), el.base64TextNoActive);
                                    }
                                    const mySound = sound.find(String(id));
                                    if (mySound) {
                                        const ques = sound.find(String(sh.id));
                                        if (ques.isPlaying){
                                            ques.stop()
                                        }
                                        mySound.play({
                                            volume: 1
                                        });
                                    }
                                }
                            }
                            shot.addChild(texture as any)
                        })
                        if(!sound.exists(String(sh.background_sound.id))) {
                            sound.add(String(sh.background_sound.id), sh.background_sound.base64);
                        }
                        const mySound = sound.find(String(sh.background_sound.id));
                        if (mySound) {
                            arrBgSound.push(mySound)
                        }
                        if(!sound.exists(String(sh.id))) {
                            sound.add(String(sh.id), sh.base64);
                        }
                        const ques = sound.find(String(sh.id));
                        arrQuestion.push(ques)
                        arrShots.push(shot)
                    })
                    this.showShot(arrShots[shotIndex], shots, arrBgSound[shotIndex], arrQuestion[shotIndex]);
                })
        }
        viewport.addChild(start as any)
        this._app.stage.addChildAt(viewport as any, 0)

        viewport
            // .drag()
            // .pinch()
            // .wheel()
            // .decelerate({friction: 0.99})
            .fit(true, size.viewport({xxs: 2.5, xs: 1, sm: 1, lg: 1, xl: 1, xxl: 1}, app.view.width), app.view.height)
    }

    showShot(shot: Container, shots: Container, bgSound: Sound, ques: Sound) {
        shots.removeChildren();
        bgSound.play({
            loop: true,
            volume: 0.01
        });
        ques.play({
            volume: 1
        });
        shots.addChild(shot as any);
    }
}

export default Game