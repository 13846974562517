import { Graphics } from "pixi.js";
import { Input as Inp } from "@pixi/ui";

class Input extends Inp {
    private _error: boolean = false;
    private _borderColor: number = 0x000000; // Цвет границы
    private _borderWidth: number = 2; // Ширина границы

    constructor(placeholder: string, width: number, height: number) {
        // Создание начального фона без ошибок
        const bg = new Graphics();
        bg.beginFill(0xFFFFFF);
        bg.drawRoundedRect(0, 0, width, height, 10);
        bg.endFill();
        // Вызов конструктора родительского класса
        super({
            bg: bg,
            textStyle: {
                fill: '#000',
                fontWeight: 'bold',
                fontSize: 30,
            },
            placeholder: placeholder,
            align: 'center',
        });

        this.drawBorder(bg, width, height, 0x000000);

        // Добавление начального фона
        this.addChildAt(bg as any, 0);
    }

    private drawBorder(graphics: Graphics, width: number, height: number, color: number) {
        graphics.lineStyle(this._borderWidth, color);
        graphics.drawRoundedRect(0, 0, width, height, 10);
    }

    set error(value: boolean) {
        this._error = value;

        // Обновление фона в зависимости от состояния ошибки
        const newBg = new Graphics();
        newBg.zIndex = -50
        if (this._error) {
            newBg.beginFill(0xFF0000)
                .drawRoundedRect(0, 0, this._width, this._height + 5, 10)
                .beginFill(0xFFFFFF)
                .drawRoundedRect(0, 0, this._width, this._height, 10);
        } else {
            newBg.beginFill(0xFFFFFF)
                .drawRoundedRect(0, 0, this._width, this._height, 10);
        }

        // Замена старого фона на новый
        this.removeChild(this._bg as any);
        this._bg = newBg;
        this.addChildAt(this._bg as any, 0);
    }

    get error(): boolean {
        return this._error;
    }
}

export default Input;
