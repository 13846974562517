import React from 'react';
import {Helmet} from "react-helmet";
import OpenGame from "../components/Game/OpenGame";

const PlayGame = () => {
    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Игры</title>
            </Helmet>
            <OpenGame/>
        </div>
    );
};

export default PlayGame;
