import React, {useEffect, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {
    setDateAdd, setGroupModalAddDO, setIsSend, setModalAddDO, setToUpdateGraphMainPageDo, WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Russian} from 'flatpickr/dist/l10n/ru'
import TableAddDO from "./tables/TableAddDO";
import {getAllInfoGroupDo, setAttendanceDO} from "../../../store/reducers/widgets/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {convertDate} from "../../../functions/ConvertDate";
import ModalSelectDeviationAllDO from "./ModalSelectDeviationAllDO";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";


const ModalAddTableAllInfoGroup = () => {

    const dispatch = useAppDispatch();
    const {modalAddDO, date, groupModalAddDO, studentsDO, groupsDO, dateAdd, isError, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [present, setPresent] = useState(0);
    const [noPresent, setNoPresent] = useState(0);
    const [modalSelectDeviationAll, setModalSelectDeviationAll] = useState(false);
    const [send, setSend] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate]);
    function presentAndNoPresent() {
        const elems: HTMLCollectionOf<Element> = document.getElementsByClassName('selectStudentsDev');
        let present = 0
        let noPresent = 0
        Array.from(elems).forEach((e) => {
            if (!(e as HTMLSelectElement).value)
                present += 1
            else
                noPresent += 1
        })
        setPresent(present)
        setNoPresent(noPresent)
    }

    function presentAndNoPresentFirst() {
        let present = 0
        let noPresent = 0
        Array.from(studentsDO).forEach((e) => {
            if (!e.deviation_code)
                present += 1
            else
                noPresent += 1
        })
        setPresent(present)
        setNoPresent(noPresent)
    }

    useEffect(() => {
        presentAndNoPresentFirst()
    }, [studentsDO])

    return (
        <Modal id="createProjectModal" isOpen={modalAddDO} toggle={() => dispatch(setModalAddDO(!modalAddDO))}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => dispatch(setModalAddDO(!modalAddDO))} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Посещаемость детей
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupModalAddDO}`, deviations: (studentsDO || []).map(item => {
                            let s: any = {}
                            s[`${item.code}`] = item.deviation_code
                            return s
                        })
                    }}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true))
                        let data = {
                            data: '',
                            class_code: '',
                            do: 'true',
                            students: [{}]
                        };
                        let arr: object[] = []
                        data.data = convertDate(dateAdd);
                        data.class_code = value.group;

                        value.deviations.forEach((element: any) => {
                            if (typeof (element) === 'object') {
                                if (Object.values(element)[0] === '') {
                                    arr.push({
                                        code: Object.keys(element)[0],
                                        attend: "1",
                                        deviation_code: null,
                                        deviation_data: null,
                                        deviation_data_prev: null

                                    })
                                } else {
                                    arr.push({
                                        code: Object.keys(element)[0],
                                        attend: "0",
                                        deviation_code: Object.values(element)[0],
                                        deviation_data: null,
                                        deviation_data_prev: null

                                    })
                                }
                            }
                        });

                        data.students = arr
                        dispatch(setAttendanceDO(data, groupModalAddDO, dateAdd))
                            .then(
                            () => {
                                dispatch(setIsSend(false))
                                dispatch(setModalAddDO(false))
                                dispatch(getAllInfoGroupDo(date)).then()
                                dispatch(setToUpdateGraphMainPageDo(true))
                            }
                        )
                            .catch(() => dispatch(setIsSend(false)))
                    }}
                >
                    <Form>
                        <div className="col-auto">
                            <label>Дата:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => dispatch(setDateAdd(moment(date[0]).toDate()))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Группа:</label>
                            <Field as="select" className="form-select shadow" value={groupModalAddDO}
                                   onChange={(e: any) => dispatch(setGroupModalAddDO(e.target.value))} name="group">
                                {groupsDO.map((group) => {
                                    return <option key={group.group_id}
                                                   value={group.group_id}>{group.group_name}</option>
                                })}
                            </Field>
                        </div>
                        <div>
                            <div className="table-responsive">
                                <table className="table shadow mb-3">
                                    <tbody>
                                    <tr>
                                        <th scope="row" className="text-success">
                                            Присутствует
                                        </th>
                                        <td>
                                            {present}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-danger">
                                            Отстутствует
                                        </th>
                                        <td>
                                            {noPresent}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{height: 400}}>
                            <TableAddDO presentAndNoPresent={presentAndNoPresent}/>
                        </div>
                        <div className="d-flex gap-5 justify-content-between">
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <button type="button" className="btn btn-primary" id="addNewProject" onClick={() => setModalSelectDeviationAll(true)}>Ввод для всех</button>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                </Formik>
                <ModalSelectDeviationAllDO isOpen={modalSelectDeviationAll} setIsOpen={(value) => setModalSelectDeviationAll(value)}/>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddTableAllInfoGroup;
