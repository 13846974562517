import React, {FC, useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import avatar1 from "../../../assets/images/users/user-dummy-img.jpg";
import {Link} from "react-router-dom";

interface ICardPortfolio{
    student: any
}
const CardPortfolio: FC<ICardPortfolio> = ({student}) => {
    return (
        <div className="mt-4" >
            <Card className="team-box">
                <div className="team-cover">
                    {
                        student.gender === 'Мужской' ?
                             <img src={process.env.REACT_APP_API_BASE_URL + '/storage/background/1639669879_173-catherineasquithgallery-com-p-fon-zvezdochki-rozovie-372.png'} alt="" className="img-fluid" />
                            :
                            <img src={process.env.REACT_APP_API_BASE_URL + '/storage/background/grizly-club-p-legkii-fon-babochki-17.jpg'} alt="" className="img-fluid" />
                    }
                </div>
                <CardBody className="p-4">
                    <Row className="align-items-center team-row">
                        <Col lg={3} className="col">
                            <div className="team-profile-img text-center">
                                <div className="avatar-xxl img-thumbnail rounded-circle overflow-hidden d-flex justify-content-center align-items-center"
                                     style={{width: '200px', height: '200px'}}>
                                    {
                                        student.portfolio?.photo ?
                                            <img src={process.env.REACT_APP_API_BASE_URL + student.portfolio.photo}
                                                 alt=""
                                                 className="img-fluid rounded-circle"
                                                 style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            :
                                            <img src={avatar1}
                                                 alt=""
                                                 className="img-fluid rounded-circle"
                                                 style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                    }
                                </div>
                                <div className="team-content">
                                    <h5 className="fs-18 mb-1">{student.fio}</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} className="col">
                            <Row className="text-muted text-center">
                                <Col xs={6} className="border-end border-end-dashed">
                                    <h5 className="mb-1">25</h5>
                                    <p className="text-muted mb-0">Всего страниц</p>
                                </Col>
                                <Col xs={6}>
                                    <h5 className="mb-1">14 (65%)</h5>
                                    <p className="text-muted mb-0">Заполнено</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} className="col">
                            <div className="text-end">
                                <Link to={`/parent/portfolio/more/${student.portfolio?.id}`} className="btn btn-light view-btn">Открыть</Link>
                                {/*<Button className="btn btn-light view-btn" onClick={() => {}}>Скачать PDF</Button>*/}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default CardPortfolio;
