import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IParent} from "../../../models/IParent";
import {IMoneySpentResponse} from "./IMoneySpentResponse";
import {IChildrenResponse} from "./IChildrenResponse";
import {IAllDeviationChildResponse} from "./IAllDeviationChildResponse";
import {IAllAttendanceResponse} from "./IAllAttendanceResponse";
import {IDeviationChildResponse} from "./IDeviationChildResponse";
import {IEvolutionResponse} from "./IEvolutionResponse";
import {IAllInfoEvolutionResponse} from "./IAllInfoEvolutionResponse";
import {IDateEvolutionResponse} from "./IDateEvolutionResponse";
import {IDateEvolution} from "../../../models/parent/IDateEvolution";
import {IFoodRationResponse} from "./IFoodRationResponse";
import {IPFCFoodRation} from "../../../models/parent/IPFCFoodRation";
import {IAllIPFCFoodRation} from "../../../models/parent/IAllIPFCFoodRation";
import {ICashAccountingTotalResponse} from "./ICashAccountingTotalResponse";
import {ICashAccountingResponse} from "./ICashAccountingResponse";
import moment from "moment";

class ParentService {
    static async getParent(): Promise<AxiosResponse<IParent[]>>{
        return $api.post<IParent[]>('/parents')
    }

    static async addParent(value: object): Promise<AxiosResponse<any>>{
        return $api.post<any>('/parent/add', value)
    }

    static async updateParent(value: object, id: number): Promise<AxiosResponse<any>>{
        return $api.post<any>(`/parent/update/${id}`, value)
    }

    static async getMoneySpent(date: Date, children: string): Promise<AxiosResponse<IMoneySpentResponse[]>>{
        const params = {
            date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],
            children
        }
        return $api.get<IMoneySpentResponse[]>('/get_money_spent', {params});
    }

    static async getChildren(): Promise<AxiosResponse<IChildrenResponse[]>>{
        return $api.get<IChildrenResponse[]>('/get_children');
    }

    static async getAllDeviationChild(date: Date, children: string, group_do: (null | string)): Promise<AxiosResponse<IAllDeviationChildResponse[]>>{
        if (group_do){
            const params = {
                date,
                children,
                group_do
            }
            return $api.get<IAllDeviationChildResponse[]>('/get_all_deviation_child', {params});
        }
        else {
            const params = {
                date,
                children
            }
            return $api.get<IAllDeviationChildResponse[]>('/get_all_deviation_child', {params});
        }

    }

    static async getAllAttendance(date: Date , children: string, group_do: (null | string)): Promise<AxiosResponse<IAllAttendanceResponse[]>>{
        if (group_do){
            const params = {
                date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],
                children,
                group_do
            }
            return $api.get<IAllAttendanceResponse[]>('/get_all_attendance', {params});
        }
        else {
            const params = {
                date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],
                children
            }
            return $api.get<IAllAttendanceResponse[]>('/get_all_attendance', {params});
        }

    }

    static async getDeviationChild(date: Date, children: string, group_do: (null | string)): Promise<AxiosResponse<IDeviationChildResponse[]>>{
        if (group_do){
            const params = {
                date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],
                children,
                group_do
            }
            return $api.get<IDeviationChildResponse[]>('/get_deviation_child', {params});
        }
        else {
            const params = {
                date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],
                children
            }
            return $api.get<IDeviationChildResponse[]>('/get_deviation_child', {params});
        }

    }

    static async getEvolutionChild(selectDatesEvolution: IDateEvolution[], children: string): Promise<AxiosResponse<IEvolutionResponse[]>>{
        const params = {
            selectDatesEvolution,
            children
        }
        return $api.get<IEvolutionResponse[]>('/get_evolution_child', {params});
    }

    static async getAllInfoEvolutionChild(children: string, selectDatesEvolution: IDateEvolution[]): Promise<AxiosResponse<IAllInfoEvolutionResponse[]>>{
        const params = {
            children,
            selectDatesEvolution
        }
        return $api.get<IAllInfoEvolutionResponse[]>('/get_all_info_evolution_child', {params});
    }

    static async getDatesEvolution(): Promise<AxiosResponse<IDateEvolutionResponse[]>>{
        return $api.get<IDateEvolutionResponse[]>('/get_dates_evolution');
    }

    static async getFoodRation(date: Date, children: string): Promise<AxiosResponse<IFoodRationResponse[]>>{
        const params = {
            date,
            children
        }
        return $api.get<IFoodRationResponse[]>('/get_food_ration', {params});
    }

    static async getPFCFoodRation(date: Date, children: string): Promise<AxiosResponse<IPFCFoodRation[]>>{
        const params = {
            date,
            children
        }
        return $api.get<IPFCFoodRation[]>('/get_pfc_food_ration_itog', {params});
    }

    static async getAllPFCFoodRation(date: Date, children: string): Promise<AxiosResponse<IAllIPFCFoodRation[]>>{
        const params = {
            date,
            children
        }
        return $api.get<IAllIPFCFoodRation[]>('/get_pfc_food_ration', {params});
    }

    static async getCashAccountingTotal(date: Date[], children: string): Promise<AxiosResponse<ICashAccountingTotalResponse[]>>{
        const params = {
            date,
            children
        }
        return $api.get<ICashAccountingTotalResponse[]>('/get_cash_accounting_itog', {params});
    }

    static async getCashAccounting(date: Date[], children: string): Promise<AxiosResponse<ICashAccountingResponse[]>>{
        const params = {
            date,
            children
        }
        return $api.get<ICashAccountingResponse[]>('/get_cash_accounting', {params});
    }

    static async getCashAccountingGraph(children: string, dateStart: Date[]): Promise<AxiosResponse<ICashAccountingResponse[]>>{
        const params = {
            children,
            dateStart
        }
        return $api.get<ICashAccountingResponse[]>('/get_cash_accounting_graph', {params});
    }

}

export default ParentService