import React, {FC, useState, useRef, useEffect} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IBigDescriptionPage{
    dispatch(value: any): any,
    page: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean) : any,
}


const BigDescriptionPage: FC <IBigDescriptionPage> = ({dispatch, page, setPortfolio, setSelectEditPagePortfolio, }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [images, setImages] = useState<{img1: any}>({ img1: ''});

    const wrapText = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number, maxWidth: number, lineHeight: number) => {
        const words = text.split(' ');
        let line = '';
        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                ctx.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            } else {
                line = testLine;
            }
        }
        ctx.fillText(line, x, y);
    };

    const handleCombineImages = () => {
        AxiosClient.get('get/pattern/portfolio', { params: { nameImage: 'aboutMe.png',  id_type: page?.selectPage?.type_page_portfolio_id, id_pattern: page?.id_pattern} })
            .then(r => {
                setImages({img1: r.data.img1});
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        if (images.img1) {
            const canvas: HTMLCanvasElement | null = canvasRef.current;
            if (!canvas) return;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;

            const img1 = new Image();
            img1.src = `data:image/png;base64,${images.img1}`;

            Promise.all([
                new Promise<void>((resolve) => { img1.onload = () => resolve(); }),
            ]).then(() => {
                ctx.drawImage(img1, 0, 0, 2480, 3508);
                ctx.font = "164px 'domino'";
                ctx.fillStyle = "DarkBlue";
                ctx.textAlign = "left";
                wrapText(ctx, page.description, 100, 450, 2280, 150);
                handleDownload();
            }).catch(error => {
                console.error("Ошибка при изображений:", error);
            });
        }
    }, [images]);

    const handleDownload = () => {
        const canvas: any = canvasRef.current;
        canvas.toBlob((blob: any) => {
            const formData = new FormData();
            formData.append('photo_page', blob);
            formData.append('id_portfolio', page?.selectPage.portfolio_child_id);
            formData.append('uid', page?.uid);
            const content = {
                description: page?.description
            };
            formData.append('content', JSON.stringify(content));
            formData.append('photo', '[]');
            formData.append('list_path_photo', page?.selectPage.photo);
            formData.append('path_photo_page', page?.selectPage.photo_page);

            AxiosClient.post(`update/page/parent/portfolio/${page.selectPage.id}`, formData, {headers: {"Content-Type": 'multipart/form-data'}})
                .then(r => {
                    setPortfolio(r.data);
                    setSelectEditPagePortfolio(false)
                    dispatch(setIsSend(false))
                })
                .catch(error => {
                    console.error("Ошибка при выгрузке страницы:", error);
                });
        }, 'image/png');
    };

    return (
        <div>
            <canvas ref={canvasRef} width={2480} height={3508} style={{ border: '1px solid black' }} />
            <br />
            <button onClick={handleCombineImages} id='handleCombineImagesBigDescriptionPage'>Combine Images</button>
            <button onClick={handleDownload}>Download as PNG</button>
        </div>
    );
};

export default BigDescriptionPage;
