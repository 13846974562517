import Modal from "../UI/Modal";
import {Application, ICanvas, Sprite, Texture} from "pixi.js";
import gsap from "gsap";
import {Viewport} from "pixi-viewport";
import iconCreate from '../../assetsGame/icons/create.png'
import iconCreateHover from '../../assetsGame/icons/createHover.png'
import iconSave from '../../assetsGame/icons/save.png'
import iconSaveHover from '../../assetsGame/icons/saveHover.png'
import GameNameModal from "./GameNameModal";
import AxiosClient from "../../api/AxiosClient";


class Menu extends Modal {
    _app: Application<ICanvas>;
    _viewport: Viewport;
    gameNameModal: GameNameModal | undefined
    constructor(app: Application<ICanvas>, viewport: Viewport) {
        super(app, 300, 50);
        this._viewport = viewport;
        this._app = app;
        this.alpha = 1
        this.x = 10;
        this.y = 0;

        const createBtn = Sprite.from(iconCreate)
        const defaultTextureCreateBtn = Texture.from(iconCreate)
        const hoverTextureCreateBtn = Texture.from(iconCreateHover)
        createBtn.y = this.height / 2 - createBtn.height / 2
        createBtn.x = 10
        createBtn.cursor = 'pointer'
        createBtn.interactive = true;
        createBtn.onmouseover = () => {
            createBtn.texture = hoverTextureCreateBtn
        }
        createBtn.onmouseout = () => {
            createBtn.texture = defaultTextureCreateBtn
        }
        createBtn.onpointerdown = () => this.createGame()

        const saveBtn = Sprite.from(iconSave)
        const defaultTextureSaveBtn = Texture.from(iconSave)
        const hoverTextureSaveBtn = Texture.from(iconSaveHover)
        saveBtn.y = this.height / 2 - saveBtn.height / 2
        saveBtn.x = 50
        saveBtn.cursor = 'pointer'
        saveBtn.interactive = true;
        saveBtn.onmouseover = () => {
            saveBtn.texture = hoverTextureSaveBtn
        }
        saveBtn.onmouseout = () => {
            saveBtn.texture = defaultTextureSaveBtn
        }
        saveBtn.onpointerdown = () => {
            const gameData = {
                name: this.gameNameModal?.name,
                shots: this.gameNameModal?.sceneGame?.shots.map(el => ({
                    personages: el.personageResult.map(el => ({...el, sprite: null, path: ''})),
                    textures: el.texturesResult.map(el => ({...el, sprite: null, path: ''})),
                    name: el.name,
                    soundBg: {...el.soundBg, base64: ''},
                    startText: el.startText,
                    bg: {...el.bg, base64: ''}
                }))
            }
            AxiosClient.post('/game/create', {game: gameData})
                .then((r) => console.log(r.data))
        }
        this.addChild(saveBtn as any)
        this.addChild(createBtn as any)

    }

    createGame = () => {
        const gameNameModal = new GameNameModal(this._app, this._viewport)
        this.gameNameModal = gameNameModal
        this._viewport.addChild(gameNameModal as any);
        gsap.to(gameNameModal, {alpha: 1, duration: 0.5});
    }
}

export default Menu;