import {Application, ICanvas} from "pixi.js";
interface SizeOptions {
    xxs?: number;
    xs?: number;
    sm?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
}
class Size {
    _app: Application<ICanvas>;
    constructor(app: Application<ICanvas>) {
        this._app = app
    }

    width(sizes: SizeOptions): number {
        const screenWidth = this._app.view.width;
        const breakpoints = [
            { width: 320, size: sizes.xxs },
            { width: 480, size: sizes.xs },
            { width: 768, size: sizes.sm },
            { width: 1024, size: sizes.lg },
            { width: 1200, size: sizes.xl },
            { width: Infinity, size: sizes.xxl }
        ];

        for (let i = 0; i < breakpoints.length; i++) {
            if (screenWidth <= breakpoints[i].width) {
                for (let j = i; j >= 0; j--) {
                    const point = breakpoints[j].size;
                    if (point !== undefined) {
                        return screenWidth / 12 * point;
                    }
                }
            }
        }

        return screenWidth;
    }

    height(sizes: SizeOptions): number {
        const screenHeight = this._app.view.height;
        const breakpoints = [
            { height: 320, size: sizes.xxs },
            { height: 480, size: sizes.xs },
            { height: 768, size: sizes.sm },
            { height: 1024, size: sizes.lg },
            { height: 1200, size: sizes.xl },
            { height: Infinity, size: sizes.xxl }
        ];

        for (let i = 0; i < breakpoints.length; i++) {
            if (screenHeight <= breakpoints[i].height) {
                for (let j = i; j >= 0; j--) {
                    const point = breakpoints[j].size;
                    if (point !== undefined) {
                        return screenHeight / 12 * point;
                    }
                }
                break; // Завершение внешнего цикла после нахождения соответствующего диапазона
            }
        }

        return screenHeight;
    }

    viewport(sizes: SizeOptions, width: number): number {
        const screenWidth = width;
        const breakpoints = [
            { height: 320, size: sizes.xxs },
            { height: 480, size: sizes.xs },
            { height: 768, size: sizes.sm },
            { height: 1024, size: sizes.lg },
            { height: 1200, size: sizes.xl },
            { height: Infinity, size: sizes.xxl }
        ];

        for (let i = 0; i < breakpoints.length; i++) {
            if (screenWidth <= breakpoints[i].height) {
                for (let j = i; j >= 0; j--) {
                    const point = breakpoints[j].size;
                    if (point !== undefined) {
                        return screenWidth * point;
                    }
                }
                break; // Завершение внешнего цикла после нахождения соответствующего диапазона
            }
        }

        return width;
    }
}

export default Size;