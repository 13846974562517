import {Application, Container, ICanvas} from "pixi.js";
import MenuShot from "./MenuShot";
import ScrollBox from "../UI/ScrollBox";
import {Viewport} from "pixi-viewport";
import Block from "./Block";


class SceneGame extends Container {
    _app: Application<ICanvas>
    _viewport: Viewport;
    shots: Block[] = []
    constructor(app: Application<ICanvas>, viewport: Viewport) {
        super();
        this._app = app;
        this._viewport = viewport;
        const scrollBox = new ScrollBox(app.view.width - 320, app.view.height - 50);
        scrollBox.y = 50
        viewport.addChild(scrollBox as any)
        const menuShot = new MenuShot(app, viewport, scrollBox, 300, 40, (data) => {
            data.index = scrollBox.items.length
            scrollBox.addItem(data as any)
            this.shots.push(data)
        })
        menuShot.x = app.view.width - menuShot.width - 5
        menuShot.y = app.view.height - menuShot.height - 5
        this.name = 'sceneGame'
        // viewport.addChild(menuShot as any)
        this.addChild(scrollBox as any)
        this.addChild(menuShot as any)
    }
}

export default SceneGame