import {Sprite} from "pixi.js";

export interface ITextures {
    x: number,
    y: number,
    path: string
    size: number,
    name: string,
    pathOrig: string,
    id: number,
    type: TypeTexture,
    textTrue: string,
    textFalse: string,
    textNoActive: string
    sprite: Sprite,
    percentY: number,
    percentX: number
}

export enum TypeTexture {
    TRUE,
    FALSE,
    NO_ACTIVE
}

export interface ISettingsTexture {
    size: number,
    name: string,
    type: TypeTexture,
    textTrue: string,
    textFalse: string,
    textNoActive: string
}

export interface ITexture {
    id: number,
    name: string,
    base64: string,
    path: string,
}