import {Application, Container, Graphics, ICanvas} from "pixi.js";

export class Modal extends Container {
    _app: Application<ICanvas>
    #bg: Graphics
    constructor(app:Application<ICanvas>, width = 700, height = 500) {
        super()
        this._app = app
        const view = new Graphics()
        view.beginFill(0xFFFFFF)
        view.drawRoundedRect(0, 0, width, height, 10)
        this.#bg = view
        this.addChild(view as any)
    }

    set setNewBg(value: Graphics) {
        this.removeChildAt(0)
        this.#bg = value
        this.addChildAt(value as any, 0)
    }

}

export default Modal;