import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import avatar1 from "../../../assets/images/users/user-dummy-img.jpg";
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {IAllDeviationGroup} from "../../../models/widgets/IAllDeviationGroup";
import {date} from "yup";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";

interface IMenuThemePortfolio {
    idPortfolio: any,
    selectThemePortfolio: any,
    setSelectThemePortfolio(value: any) : any
}

const MenuThemePortfolio :FC <IMenuThemePortfolio> = ({idPortfolio, setSelectThemePortfolio, selectThemePortfolio}) => {
    const [date, setDate] = useState<any>([]);

    useEffect(() => {
        AxiosClient.get('theme_all/parent/portfolio', {params: {}})
            .then(r => {
                setDate(r.data)
            })
            .catch(() => console.log('error'))
    }, [])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Название темы</span>,
            selector: (row: any) => row.name,
            //cell: (row: any) => <span >row.name</span>,
            sortable: false,
        },
        // {
        //     id: "count",
        //     name: <span className='font-weight-bold fs-13'>Количество страниц</span>,
        //     selector: (row: any) => row.pages,
        //     sortable: false,
        //     center: true,
        // }
    ];

    return (
        <div>
            <Card className="team-box">
                <CardBody className="p-4">
                    <DataTable
                        columns={columns}
                        data={date}
                        noDataComponent={'Нет тем'}
                        defaultSortAsc={false}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                },
                                // style: (row: any) => ({
                                //     backgroundColor: row.id === selectThemePortfolio ? '#ffebb5' : '', // Условная подсветка выбранной строки
                                // }),
                            }
                        }}
                        onRowClicked={(row) => {
                            setSelectThemePortfolio({value: row.id, label: row.name})
                        }}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default MenuThemePortfolio;
