import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import AxiosClient from "../../../../api/AxiosClient";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import TitlePortfolioPage from "../portfolioPage/TitlePortfolioPage";
import {useAppDispatch} from "../../../../hooks/redux";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";


interface IAddTitlePage {
    portfolio: any,
    setPortfolio(value: any): any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean) : any
}

const EditTitlePage: FC <IAddTitlePage> = ({ portfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, setPortfolio}) => {
    const dispatch = useAppDispatch();
    const [editPortfolio, setEditPortfolio] = useState<any>();
    const [filePhoto, setFilePhoto] = useState(portfolio?.photo != '' ? process.env.REACT_APP_API_BASE_URL+ portfolio?.photo : userDummayImage)
    const [fileForm64, setFileForm64] = useState<string | ArrayBuffer | null>('');
    const [fileForm, setFileForm] = useState<string | Blob>('');
    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);

    useEffect(() => {
        if(isEditPortfolioPage){
            document.getElementById('handleCombineImagesTitle')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [editPortfolio])

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение титульной страницы
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                id: portfolio?.id,
                                child_uid: portfolio?.child_uid,
                                last_name: portfolio?.last_name,
                                name: portfolio?.name,
                                middle_name: portfolio?.middle_name,
                                photo: fileForm,
                                photo_download: fileForm64,
                                path_photo: portfolio?.photo,
                                path_photo_page: portfolio?.photo_page,
                            }
                        }
                        validationSchema={
                            Yup.object({
                                name: Yup.string().required('Обязательное поле'),
                                last_name: Yup.string().required('Обязательное поле')
                            })
                        }
                        onSubmit={(values) => {
                            dispatch(setIsSend(true))
                            setEditPortfolio({...values})
                            setIsEditPortfolioPage(true);
                            //document.getElementById('handleCombineImagesTitle')?.click();
                            // const formData: any = new FormData();
                            // formData.append('last_name', values.last_name);
                            // formData.append('name', values.name);
                            // formData.append('middle_name', values.middle_name);
                            // formData.append('id', portfolio.id);
                            // formData.append('pattern_page_portfolio_id', portfolio.pattern_page_portfolio_id);
                            // formData.append('uid', portfolio.child_uid);
                            // formData.append('photoPath', portfolio?.photo);
                            // if (values.photo) {
                            //     formData.append('photo', fileForm);
                            // }

                            // AxiosClient.post('update/parent/portfolio', formData, {headers: {"Content-Type": 'multipart/form-data'}})
                            //     .then(r => {
                            //         setIsEditPortfolioPage(true);
                            //         setPortfolio(r.data);
                            //     })
                            //     .catch(error => { console.error("Ошибка при обновлении порфтолио:", error) });
                        }}
                    >
                        {
                            ({errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Имя:
                                                <Field name="name" className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Фамилия:
                                                <Field name="last_name" className={`form-control ${touched.last_name && errors.last_name ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Отчество:
                                                <Field name="middle_name" className={`form-control ${touched.middle_name && errors.middle_name ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="w-100">
                                                Фото (.jpg, .png):
                                                <Input
                                                    name="photo"
                                                    type="file"
                                                    className={`form-control ${touched.photo && errors.photo ? 'is-invalid' : ''}`}
                                                    accept=".png, .jpg"
                                                    onChange={(e: any) => {
                                                        const file = e.target.files[0];

                                                        if (file) {
                                                            setFileForm(file);
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                setFileForm64(reader.result); // reader.result будет содержать base64 строку
                                                                setFieldValue('photo', reader.result);
                                                                setFilePhoto(reader.result as string); // Используем base64 строку для предпросмотра изображения
                                                            };
                                                            reader.readAsDataURL(file);
                                                        }
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="my-2">
                                        <Col>
                                            <img
                                                src={filePhoto}
                                                className="avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"/>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'}/>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            <TitlePortfolioPage dispatch={(value) => dispatch(value)} portfolio={editPortfolio} setPortfolio={(value) => setPortfolio(value)} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></TitlePortfolioPage>
        </>
    );
};

export default EditTitlePage;
