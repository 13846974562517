import {Application, Container, Graphics, ICanvas, Sprite, Texture} from "pixi.js";
import plusTexture from "../../assetsGame/plus.png";
import gsap from "gsap";
import Shot from "./Shot";
import {Viewport} from "pixi-viewport";
import Block from "./Block";
import ScrollBox from "../UI/ScrollBox";
class MenuShot extends Container {
    _app: Application<ICanvas>;
    _viewport: Viewport;
    _addShot: (data: Block) => void
    _scrollBox: ScrollBox
    shots: Block[] = []
    constructor(app: Application<ICanvas>, viewport: Viewport, scrollBox: ScrollBox, width: number, height: number, addShot: (data: Block) => void) {
        super();
        this._addShot = addShot
        this._app = app;
        this._viewport = viewport
        this._scrollBox = scrollBox
        const view = new Graphics()
        view.beginFill(0xFFFFFF)
        view.drawRoundedRect(0, 0, width, height, 10)
        const plusTextur = Texture.from(plusTexture)
        const plus = new Sprite()
        plus.anchor.set(0.5)
        plus.cursor = 'pointer'
        plus.texture = plusTextur;
        plus.width = 30;
        plus.height = 30;
        plus.x = 20;
        plus.y = view.height / 2;
        plus.onpointerdown = () => {
            this.visible = false;
            this.addElem()
        }
        view.addChild(plus as any);
        this.name = 'menuShot'
        this.addChild(view as any)
    }

    addElem() {
        this._scrollBox.visible = false
        const shot = new Shot(this._app, this._viewport, this._scrollBox, (data) => {
            this._addShot(data)
            this._scrollBox.visible = true
            this.visible = true
        })
        const modal = shot.addShot();
        // this._modalAddShot = new SettingsShot(this._app, (name) => {
        //     this._addShot(new Shot(name, this._app))
        //     view.visible = true;
        // })
        this._viewport.addChild(modal as any)
        gsap.to(modal, {alpha: 1, duration: 0.5});

        //this._app.stage.addChild(this._modalAddShot as any);
    }
}

export default MenuShot;