import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import bg from '../../assetsGame/icons/bgStartEnd.jpg'

const ListGames = () => {
    const [games, setGames] = useState<{ id: number, name: string }[]>([]);
    useEffect(() => {
        AxiosClient.get('/game')
            .then((r) => setGames(r.data))
    }, []);

    return (
        <div>
            <Row>
                {
                    games.map(el => (
                        <Col xxl={3} xl={4} lg={6} key={el.id}>
                            <Link to={`/game/${el.id}`} className="w-100">
                                <Card>
                                    <CardBody>
                                        <img src={bg} alt="bg" className="w-100 h-100"/>
                                    </CardBody>
                                    <CardFooter>
                                        <h4 className="m-0">{el.name}</h4>
                                    </CardFooter>
                                </Card>
                            </Link>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default ListGames;
