import React, {FC, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalUpdateClass from "./ModalUpdateClass";
import AxiosClient from "../../../api/AxiosClient";

interface IListClass {
    listClass: any[],
    openListClass: boolean,
    setOpenListClass: (value: boolean) => void;
    builds: any[],
    setBuilds: (value: any[]) => void,
    selectBuild: string,
    newBuilds: any[],
    setNewBuilds: (value: any[]) => void
}
const ListUpdateClass: FC<IListClass> = ({openListClass, setOpenListClass, listClass, builds, setBuilds, selectBuild, setNewBuilds, newBuilds}) => {
    //console.log(listClass);
    const [selectClass, setSelectClass] = useState<any | null>(null);
    const [updateClass, setUpdateClass] = useState(false);
    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Класс</span>,
            selector: (row: any) => row.name,
        },
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Параллели</span>,
            selector: (row: any) => row.parallel_class.name,
        },
        {
            cell: (row: any) => <i className="bx bx-pencil fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                setSelectClass(row)
                setUpdateClass(true)
            }}></i>,
            center: true,
            width: '70px'
        },
        {
            cell: (row: any) => row.id && <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                setBuilds(builds.map(b => {
                    if (b.name === selectBuild){
                        return {
                            ...b,
                            class: b.class.filter((c: any) => c.name !== row.name)
                        }
                    }
                    return b
                }))
                AxiosClient.post(`/schools/archive_class/${row.id}`)
                    .catch(() => console.log('error'))
            }}></i>,
            center: true,
            width: '70px'
        }
    ]

    return (
        <Modal id="createProjectModal" isOpen={openListClass}
               toggle={() => setOpenListClass(!openListClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setOpenListClass(!openListClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Классы
            </ModalHeader>
            <ModalBody>
                <DataTable
                    columns={columns}
                    data={listClass}
                    noDataComponent={'Нет данных'}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно классов:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </ModalBody>
            <ModalUpdateClass updateClass={updateClass} setUpdateClass={value => setUpdateClass(value)} builds={builds} setBuilds={value => setBuilds(value)} newBuilds={newBuilds} setNewBuilds={value => setNewBuilds(value)} selectClass={selectClass} selectBuild={selectBuild}/>
        </Modal>
    );
};

export default ListUpdateClass;
