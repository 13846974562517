import React, {FC, memo, useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import {Alert, Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import QRCode from "react-qr-code";
import * as Yup from 'yup';
import {showtime} from "../../functions/showTime";
import {showPhone} from "../../functions/showPhone";
import AuthService from "../../api/services/authService/AuthService";

interface ILoginModal {
    modalCode: boolean,
    setModalCode: (modalCode: boolean) => any;
    phone: string
    setCode: (code: boolean) => any;
}

const LoginModal: FC<ILoginModal> = ({modalCode, setModalCode, phone, setCode}) => {

    const [blockTime, setBlockTime] = useState<number>(180)
    const [startTime, setStartTime] = useState<boolean>(false)
    const [buttonDis, setButtonDis] = useState<boolean>(false)
    const [sendingCode, setSendingCode] = useState<boolean>(false);
    const [message, setMessage] = useState<string[]>([])
    const [time, setTime] = useState(false)

    const minuts: number = showtime(Math.floor(blockTime / 60))

    const seconds = showtime(blockTime - minuts * 60)

    const QRCodee: any = memo(QRCode)


    useEffect(() => {
        var interval = setInterval(() => time && setBlockTime((blockTime) => (blockTime >= 1 ? blockTime - 1 : 0)), 1000)

        if (blockTime === 0) {
            setTime(false);
            setStartTime(false)
            setButtonDis(false)
        }

        return () => clearInterval(interval)

    }, [time, blockTime])

    useEffect(() => {
        let timer: any;
        if (message.length > 1) {
            timer = setTimeout(() => {
                setMessage([])
            }, 5000);
        }
        return () => clearTimeout(timer)
    }, [message]);


    return (
        <Modal id="createProjectModal" isOpen={modalCode} toggle={() => setModalCode(!modalCode)}
               modalClassName="zoomIn" tabIndex={-1} centered size="sm">
            <ModalHeader toggle={() => setModalCode(!modalCode)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Код подтверждения
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        'phone': phone,
                        'code': ''
                    }}
                    validationSchema={
                        sendingCode ?
                            Yup.object({
                                code: Yup.string().required('Введите код'),
                                phone: Yup.string().required('Введите телефон'),
                            })
                            :
                            Yup.object({
                                phone: Yup.string().required('Введите телефон'),
                            })
                    }
                    onSubmit={({code}) => {
                        if (sendingCode) {
                            AuthService.checkCode(phone, code)
                                .then(
                                    () => {
                                        setCode(true)
                                        setMessage([]);
                                        setModalCode(false)
                                    }
                                ).catch(
                                error => {
                                    if (error.response.status === 422) {
                                        setMessage([error.response.data, 'danger'])
                                    }
                                }
                            )
                        } else {
                            AuthService.sendCode(phone)
                                .then(
                                    (response) => {
                                        setBlockTime(180);
                                        setTime(true);
                                        setStartTime(true);
                                        setSendingCode(false)
                                        setSendingCode(true)
                                        setButtonDis(true)
                                        setMessage([response.data, 'success']);
                                    }
                                )
                                .catch(
                                    error => {
                                        if (error.response.status === 422) {
                                            setMessage([error.response.data, 'danger'])
                                        }
                                    }
                                )
                        }
                    }}
                >
                    <Form>
                        {
                            message.length > 0 && <Alert color={message[1]}>{message[0]}</Alert>
                        }
                        <Row>
                            <Col>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-1">
                                            <Label htmlFor="roleInput" className="form-label">Номер телефона</Label>
                                            <Field className="form-control" name="phone" value={showPhone(phone)}/>
                                        </div>

                                    </Col>
                                    {
                                        sendingCode &&
                                        <Col lg="12">
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput" className="form-label">Код</Label>
                                                <Field className="form-control" name="code"/>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        {
                            sendingCode &&
                            <button type="submit" className="btn btn-primary mb-3" style={{'width': '100%'}}
                                    id="inSystem">Вход в систему
                            </button>
                        }

                        <button type="submit" disabled={buttonDis} style={{'width': '100%'}}
                                className="btn btn-primary" id="sendCode">
                            {startTime ? 'Получить код повторно через ' + minuts + ':' + seconds : 'Получить код'}
                        </button>

                    </Form>
                </Formik>
                <QRCodee value="https://t.me/mydou_bot" style={{'marginTop': '10'}} size={250}/>
                <p style={{'marginTop': '10px'}}>Подключитесь к боту Telegram по QR-code или перейдите по ссылке <a
                    href='https://t.me/mydou_bot' onClick={(e) => {
                    e.preventDefault();
                    window.open('https://t.me/mydou_bot', '_blank');
                }} className="text-danger">Ссылка на бота</a> или вставьте в строку поиска по диалогам @mydou_bot что-бы
                    зарегестрировать свой номер телефона в нашей системе. Если вы этого еще не сделали.</p>
            </ModalBody>
        </Modal>
    );
};

export default LoginModal;
