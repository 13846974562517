import {ScrollBox as SB} from "@pixi/ui";
class ScrollBox extends SB {
    constructor(width: number, height: number) {
        super({
            elementsMargin: 10,
            width: width,
            height: height,
            radius: 20,
            padding: 10,
        });
    }
}

export default ScrollBox;