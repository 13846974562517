import {Application, ICanvas} from "pixi.js";
import Button from "../UI/Button";
import gsap from "gsap";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Block from "./Block";
import {Viewport} from "pixi-viewport";
import ScrollBox from "../UI/ScrollBox";

class Shot {
    _app: Application<ICanvas>
    name = '';
    shot: Modal | undefined;
    block: Block | undefined
    _viewport: Viewport
    _addShot: (block: Block) => void
    _scrollBox: ScrollBox
    constructor(app: Application<ICanvas>, viewport: Viewport, scrollBox: ScrollBox, addShot: (block: Block) => void){
        this._app = app;
        this._viewport = viewport
        this._addShot = addShot
        this._scrollBox = scrollBox
    }

    addShot() {
        const modal = new Modal(this._app)
        modal.alpha = 0;
        modal.x = this._app.view.width / 2 - modal.width / 2;
        modal.y = this._app.view.height / 2 - modal.height / 2;

        const input= new Input('Название кадра', 300, 50);
        input.x = modal.width / 2 - input.width / 2;
        input.y = modal.height / 3;
        input.value = this.name;
        input.onChange.connect((text) => {
            this.name = text
        })

        const btnAdd = new Button('Добавить', 300, 50);
        btnAdd.view.x = modal.width / 2 - btnAdd.view.width / 2;
        btnAdd.view.y = modal.height / 2
        btnAdd.view.onpointerdown = () => {
            gsap.to(modal, {
                alpha: 0, duration: 0.5, onComplete: () => {
                    const block =  new Block(this._app, this._viewport, this._scrollBox, this.name)
                    this.block = block
                    this._addShot(block)
                    modal.destroy();
                }
            })
            // addShot(this.name)
        };
        modal.addChild(input as any);
        modal.addChild(btnAdd.view as any);
        this.shot = modal
        return modal
    }

    show() {
        return this.block
    }
}

export default Shot;