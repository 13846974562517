import React, {useEffect, useRef} from 'react';
import {Application} from "pixi.js";
import Game from "../../game/Game";

const GameComponent = () => {
    const ref = useRef<any>(null);

    useEffect(() => {
        const app = new Application({
            width: ref.current.offsetWidth,
            height: ref.current.offsetHeight,
            autoDensity: true,
            backgroundColor: 0xEFEFFF,
            eventMode: 'static',
        });
        ref.current.appendChild(app.view);
        new Game(app)
        app.start();
        return () => {
            app.destroy(true, true);
        };
    }, []);

    return (
        <>
            <div ref={ref} style={{overflow: 'hidden', overflowY: 'hidden', width: '100%', height: '100%'}}></div>
        </>
    );
};

export default GameComponent;
