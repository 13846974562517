import {Graphics, Text} from "pixi.js";
import {Button as Btn} from "@pixi/ui";

class Button extends Btn {
    constructor(text: string, width: number, height: number, radius = 10) {
        super()
        const view = new Graphics().beginFill('#EFEFFF').drawRoundedRect(0, 0, width, height, radius);
        view.interactiveChildren = false;
        //view.onpointerdown = () => onpointerdown()
        const textElem = new Text(text, {fontSize: 30, align: 'center'});
        textElem.anchor.set(0.5)
        textElem.x = view.width / 2;
        textElem.y = view.height / 2;
        view.addChild(textElem as any);
        this.view = new Btn(view).view;
    }
}

export default Button;