import React from 'react';
import {Helmet} from "react-helmet";
import ListGames from "../components/Game/ListGames";

const Games = () => {
    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Игры</title>
            </Helmet>
            <ListGames/>
        </div>
    );
};

export default Games;
