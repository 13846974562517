import {AppDispatch} from "../../store";
import {ParentSlice} from "./ParentSlice";
import ParentService from "../../../api/services/parentService/ParentService";
import {IDateEvolution} from "../../../models/parent/IDateEvolution";

export const getMoneySpent = (date: Date, children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getMoneySpent(date, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessMoneySpent(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getChildren = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getChildren().then(res => res.data)
        dispatch(ParentSlice.actions.setChild(response[0].account_uid))
        dispatch(ParentSlice.actions.dataSuccessChildren(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllDeviationChild = (date: Date, children: string, group_do: (null | string) = null) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getAllDeviationChild(date, children, group_do).then(res => res.data)

        if(!group_do){
            dispatch(ParentSlice.actions.dataSuccessAllDeviationChild(response))
        }else{
            dispatch(ParentSlice.actions.dataSuccessAllDeviationChildPaid(response))
        }

    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllAttendance = (date: Date, children: string, group_do: (null | string) = null) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getAllAttendance(date, children, group_do).then(res => res.data)

        if(!group_do){
            dispatch(ParentSlice.actions.dataSuccessAllAttendance(response))
        }else{
            dispatch(ParentSlice.actions.dataSuccessAllAttendancePaid(response))
        }

    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getDeviationChild = (date: Date, children: string, group_do: (null | string) = null) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getDeviationChild(date, children, group_do).then(res => res.data)
        if (!group_do){
            dispatch(ParentSlice.actions.dataSuccessDeviationChild(response))
        }else {
            dispatch(ParentSlice.actions.dataSuccessDeviationChildPaid(response))
        }
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getEvolutionChild = (selectDatesEvolution: IDateEvolution[], children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getEvolutionChild(selectDatesEvolution, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessEvolutionChild(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllInfoEvolutionChild = (children: string, selectDatesEvolution: IDateEvolution[]) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getAllInfoEvolutionChild(children, selectDatesEvolution).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessAllInfoEvolutionChild(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getDatesEvolution = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getDatesEvolution().then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessDatesEvolution(response))
        dispatch(ParentSlice.actions.setSelectDatesEvolution(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getFoodRation = (date: Date, children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getFoodRation(date, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessFoodRation(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getPFCFoodRation = (date: Date, children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getPFCFoodRation(date, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessPFCFoodRation(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllPFCFoodRation = (date: Date, children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getAllPFCFoodRation(date, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessAllPFCFoodRation(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getCashAccountingTotal = (date: Date[], children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getCashAccountingTotal(date, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessCashAccountingTotal(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getCashAccounting = (date: Date[], children: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getCashAccounting(date, children).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessCashAccounting(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}

export const getCashAccountingGraph = (children: string, dateStart: Date[]) => async (dispatch: AppDispatch) => {
    try {
        dispatch(ParentSlice.actions.dataLoading())
        const response = await ParentService.getCashAccountingGraph(children, dateStart).then(res => res.data)
        dispatch(ParentSlice.actions.dataSuccessCashAccountingGraph(response))
    }catch (e: any){
        dispatch(ParentSlice.actions.dataLoadingError(e.message))
    }
}